import React, { useEffect, useState } from 'react';
import { Col, Row, Form } from 'reactstrap';
import { useFieldArray, useForm } from 'react-hook-form';
import FormSelect from 'component/common/FormSelect';
import {
  facilityExp,
  patientExp,
  state,
  facilityTypeOptions,
  patientTypeOptions,
  dressCodeOptions,
  boolList,
} from '../../../authentication/signUpClinician/HourlyConstant';
import {
  ACCEPT_IMAGE,
  MAX_LENGTH,
  REFERENCEBY,
  RESPONSE_OK,
} from 'constants/AppConstants';
import FormInput from 'component/common/FormInput';
import {
  AboutFacilityRules,
  checkInPlaceForFirstDayRules,
  CommentRules,
  DressCodeRules,
  EmrRules,
  FirstNameRules,
  invoiceMethodRules,
  isCPRRequiredRules,
  LastNameRules,
  NameRules,
  NumberOfCliniciansRules,
  OfficeNameRules,
  OfficeNickNameRules,
  PatientSeenPerHourRules,
  phoneRules,
  phoneRulesOptional,
  RolesRules,
  StateRules,
  TypeOfFacilityRules,
  TypeOfPatientsRules,
  ZipCodeRules,
} from 'constants/Rules';
import FormRadio from 'component/common/FormRadio';
import { isEmpty, omit, pick } from 'radash';
import FormCleave from 'component/common/FormCleave';
import { findAndReplace, getOtherDataFromList, removeOther } from 'utils/Utils';
import FormCheckboxOther from 'component/common/FormCheckboxOther';
import FormError from 'component/common/FormError';
import UploadFile from 'component/common/uploadFile';
import { useParams } from 'react-router-dom';
import { api } from 'api/Api';
import {
  GET_FACILITY_DATA,
  RESET_FACILITY_PASSWORD_URL,
  UPDATE_FACILITY_URL,
} from 'constants/ApiUrls';
import FormButton from 'component/common/FormButton';
import { Close, Pencil } from 'assets/svg';
import EditEmailModal from 'component/modals/EditEmailModal';
import ResetFacilityPasswordModal from 'component/modals/ResetFacilityPasswordModal';
import PlacesInput from 'component/common/PlacesInput';

export default function AdminFacilityGeneralInformation(editable) {
  const {
    register,
    trigger,
    control,
    reset,
    watch,
    unregister,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      facilityExperience: facilityExp,
      patientExperience: patientExp,
      dressCode: [],
      facilityType: [],
      patientsType: [],
      officeAddressZipCode: '',
      emailsForInvoice: [],
    },
  });
  const facilityType = watch('facilityType');
  const patientsType = watch('patientsType');
  const dressCode = watch('dressCode');
  const isCPRRequired = watch('isCPRRequired');
  const dressCodeOther = watch('dressCodeOther');
  const [initData, setInitData] = useState({}); // set initial form data
  const [loader, setLoader] = useState(false);
  const [user, setUser] = useState(false);
  const [otherFormData, setOtherFormData] = useState({});
  const [otherFormErrors, setOtherFormErrors] = useState({});
  const [editEmailModal, setEditEmailModal] = useState(false);
  const [resetPasswordModal, setResetPasswordModal] = useState(false);
  const params = useParams();

  const {
    fields: locaFields,
    append: locaAppend,
    // remove: locaRemove,
  } = useFieldArray({
    control,
    name: 'secondaryAddresses',
  });

  const locaRemove = (indexToRemove) => {
    const data = watch('secondaryAddresses');
    const updatedLocaFields = data.filter(
      (_, index) => index !== indexToRemove?.index,
    );
    setValue('secondaryAddresses', updatedLocaFields);
  };

  useEffect(() => {
    if (!facilityType.includes('Other')) {
      setValue('facilityTypeOther', '');
      unregister('facilityTypeOther');
    }
    if (!patientsType.includes('Other')) {
      setValue('patientsTypeOther', '');
      unregister('patientsTypeOther');
    }
  }, [facilityType, patientsType]);

  useEffect(() => {
    if (isCPRRequired === 'false') {
      setValue('CPRRequirement', '');
      unregister('CPRRequirement');
    }
  }, [isCPRRequired]);

  useEffect(() => {
    if (!isEmpty(user)) setDataToForm();
  }, [user]);

  useEffect(() => {
    getFacilityById();
  }, []);

  const getFacilityById = () => {
    setLoader(true);
    setResetPasswordModal(false);

    api(GET_FACILITY_DATA, {}, null, {
      id: params.id,
    }).then((res) => {
      if (res.status === RESPONSE_OK) setUser(res.data.data);
      setLoader(false);
    });
  };
  // get data from user context and set data to form
  const setDataToForm = () => {
    // merge user data and facility data
    let data = {
      dressCode: [],
      facilityType: [],
      patientsType: [],
      ...user?.facilityId,
      ...user,
    };
    // convert server data to form data
    data.isTrainingVideosForClinician = data.isTrainingVideosForClinician
      ? 'true'
      : 'false';
    data.isTestCompletedForClinician = data.isTestCompletedForClinician
      ? 'true'
      : 'false';
    data.isCPRRequired = data.isCPRRequired ? 'true' : 'false';
    // extract other patient data from server data
    data.patientsTypeOther = getOtherDataFromList(
      patientTypeOptions,
      data.patientsType,
    );
    if (data.patientsTypeOther) {
      data.patientsType.push('Other');
      data.patientsType = data.patientsType.filter(
        (type) => type !== data.patientsTypeOther,
      );
    }

    // extract other facility data from server data
    data.facilityTypeOther = getOtherDataFromList(
      facilityTypeOptions,
      data.facilityType,
    );

    if (data.facilityTypeOther) {
      data.facilityType.push('Other');
      data.facilityType = data.facilityType.filter(
        (type) => type !== data.facilityTypeOther,
      );
    }

    // extract other dress code data from server data
    data.dressCodeOther = getOtherDataFromList(
      dressCodeOptions,
      data.dressCode,
    );
    if (data.dressCodeOther) {
      data.dressCode = data.dressCode.filter(
        (type) => type !== data.dressCodeOther,
      );
    }
    // // extract other emr data from server data
    // data.emrListOther = getOtherDataFromList(emrListOptions, data.emrList);
    // if (data.emrListOther) {
    //   data.emrList.push("Other");
    //   data.emrList = data.emrList.filter((type) => type !== data.emrListOther);
    // }
    reset({ ...data });
    setInitData({ ...data });

    setOtherFormData({
      ProfilePicUrl: data.ProfilePicUrl,
      facilityPicUrl: data.facilityPicUrl,
    });
  };

  const fileCallbackFn = (res, id, multiple) => {
    setOtherFormData({
      ...otherFormData,
      [id]: multiple ? [...otherFormData[id], ...res] : res[0],
    });
  };

  const handleSave = async (obj, loaderValue) => {
    let reqData = {};
    let result = await trigger(obj);
    if (result) {
      let values = {
        ...getValues(),
        ...otherFormData,
      };
      if (loaderValue === 1) {
        if (!otherFormData.ProfilePicUrl) {
          setOtherFormErrors({ ProfilePicUrl: 'Please add Profile Picture' });
          return;
        } else setOtherFormErrors({ ProfilePicUrl: '' });
      }

      if (loaderValue === 5) {
        values.facilityType = findAndReplace(
          values.facilityType,
          'Other',
          values?.facilityTypeOther,
        );
        values.patientsType = findAndReplace(
          values.patientsType,
          'Other',
          values?.patientsTypeOther,
        );
        // values.dressCode = findAndReplace(
        //   values.dressCode,
        //   "Other",
        //   values.dressCodeOther
        // );
        values.dressCode = [
          ...(values?.dressCode || []),
          values.dressCodeOther,
        ].filter((a) => a);
        // values.emrList = findAndReplace(
        //   values.emrList,
        //   "Other",
        //   values.emrListOther
        // );
        values.isCPRRequired = values.isCPRRequired === 'true';
        values = omit(values, [
          'referenceByOther',
          'facilityTypeOther',
          'patientsTypeOther',
          'dressCodeOther',
          'emrListOther',
        ]);
      }

      if (loaderValue === 6) {
        values.emailsForInvoice =
          values?.emailsForInvoice?.filter((s) => s) || [];
      }
      reqData = pick(values, obj);
      onSave(reqData, loaderValue);
    }
  };

  const onSave = (reqData, loaderValue) => {
    let tempInitData = {
      ...initData,
      dressCode: removeOther(user?.dressCode),
      // emrList: removeOther(user?.emrList),
      facilityType: removeOther(user?.facilityType),
      patientsType: removeOther(user?.patientsType),
    };

    setLoader(loaderValue);
    let data = {
      ...tempInitData,
      ...reqData,
      id: initData?.id,
      userId: initData?.userId?.id,
    };
    const tempValues = getValues();

    api(UPDATE_FACILITY_URL, data).then((res) => {
      if (res.status === RESPONSE_OK) {
        setInitData({ ...tempValues, ...reqData });
      }
      setLoader(false);
    });
  };

  const handleReset = (userId) => {
    api(RESET_FACILITY_PASSWORD_URL, null, (userId = initData.userId.id)).then(
      (res) => {
        if (res.status === RESPONSE_OK) {
        }
        setLoader(false);
      },
    );
  };

  const primaryFields = [
    'primaryFirstName',
    'primaryLastName',
    'primaryEmail',
    'primaryPhone',
    'primaryRole',
  ];
  const invoiceFields = ['invoiceMethod', 'emailsForInvoice'];
  const clientDetails = [
    'numberOfClinicians',
    'facilityType',
    'facilityTypeOther',
    'patientsType',
    'patientsTypeOther',
    'dressCode',
    'dressCodeOther',
    'emrList',
    'patientsSeenPerHour',
    'isTrainingVideosForClinician',
    'isTestCompletedForClinician',
    'parkingSituation',
    'aboutFacility',
    'checkInPlaceForFirstDay',
    'isCPRRequired',
    'CPRRequirement',
  ];
  return (
    <div className="general-content">
      <Form>
        <fieldset>
          <div className="first-block">
            <div className="general-photo-main">
              <div className="picture-block">
                <h5>Profile picture (required)</h5>
                <p>
                  Select a high quality primary profile image to represent
                  yourself
                </p>
                <div className="uploaded-pic clinician">
                  <UploadFile
                    // hideRemoveBtn={true}
                    serverFiles={
                      otherFormData.ProfilePicUrl
                        ? [otherFormData.ProfilePicUrl]
                        : []
                    }
                    multiple={false}
                    id="ProfilePicUrl"
                    accept={ACCEPT_IMAGE}
                    folder="images"
                    max={1}
                    callbackFn={fileCallbackFn}
                    deleteCallbackFn={(respData, id) =>
                      setOtherFormData({ ...otherFormData, [id]: respData })
                    }
                  />
                </div>
                <FormError msg={otherFormErrors?.['ProfilePicUrl']} />
              </div>
              <div className="picture-block">
                <h5>Additional Facility Photos (optional)</h5>
                <div className="uploaded-pic">
                  <UploadFile
                    // hideRemoveBtn={true}
                    multiple={true}
                    serverFiles={otherFormData.facilityPicUrl}
                    max={5}
                    id="facilityPicUrl"
                    accept={ACCEPT_IMAGE}
                    folder="images"
                    callbackFn={fileCallbackFn}
                    deleteCallbackFn={(respData, id) =>
                      setOtherFormData({ ...otherFormData, [id]: respData })
                    }
                  />{' '}
                </div>
              </div>
            </div>
            <div className="office-block office">
              <h5>Facility name</h5>
              <p>
                This will be displayed to our users, so please include the full
                name. If you have multiple locations, indicate which one it is
                within the name, e.g. Pinnacle Physical Therapy - Riverdale
              </p>
            </div>
            <FormInput
              name="officeName"
              id="officeName"
              type="text"
              register={register}
              rules={OfficeNameRules}
              errors={errors}
              divClassName="signup-input office-name "
            />
            <div className="text-center mt-4">
              <FormButton
                className="pt-btn btn-primary pt-btn-small"
                type={'button'}
                label={'Save'}
                loader={loader === 1}
                onClick={() =>
                  handleSave(
                    ['officeName', 'ProfilePicUrl', 'facilityPicUrl'],
                    1,
                  )
                }
              />
            </div>
          </div>
          <div className="primary-contact">
            <h5>Email</h5>
            <p className="email-block">
              <b className="email">
                {initData?.userId?.email}
                {editable && (
                  <span
                    style={{ cursor: 'pointer', marginLeft: '3px' }}
                    className="pencilicon"
                    onClick={() => setEditEmailModal(!editEmailModal)}>
                    <Pencil />
                  </span>
                )}
                <br />
                <FormButton
                  className="pt-btn btn-primary reset"
                  type={'button'}
                  label={'Reset Password'}
                  onClick={() => {
                    setResetPasswordModal(!resetPasswordModal);
                    // handleReset();
                  }}
                />
              </b>
            </p>
          </div>
          <div className="primary-contact">
            <h5>{REFERENCEBY}</h5>
            <p>
              <b>{initData?.referenceFrom}</b>
            </p>
          </div>
          <div className="primary-contact">
            <h5>Primary contact</h5>

            <Row>
              <Col md={6}>
                <FormInput
                  name={'primaryFirstName'}
                  id={'primaryFirstName'}
                  type={'text'}
                  label={'First Name:'}
                  register={register}
                  rules={FirstNameRules}
                  errors={errors}
                  divClassName="form-group pr-12"
                />
              </Col>
              <Col md={6}>
                <FormInput
                  name={'primaryLastName'}
                  id={'primaryLastName'}
                  type={'text'}
                  label={'Last Name:'}
                  register={register}
                  rules={LastNameRules}
                  errors={errors}
                  divClassName="form-group pl-12"
                />
              </Col>
              <Col md={6}>
                <FormCleave
                  trigger={trigger}
                  name={'primaryPhone'}
                  id={'primaryPhone'}
                  type={'number'}
                  label={'Phone number:'}
                  control={control}
                  rules={phoneRules}
                  errors={errors}
                  divClassName="form-group pr-12"
                />
              </Col>
              <Col md={6}>
                <FormInput
                  name={'primaryRole'}
                  id={'primaryRole'}
                  type={'text'}
                  label={'Role:'}
                  register={register}
                  rules={RolesRules}
                  errors={errors}
                  divClassName="form-group pl-12"
                />
              </Col>
            </Row>
            <div className="text-center">
              <FormButton
                className="pt-btn btn-primary pt-btn-small"
                type={'button'}
                label={'Save'}
                loader={loader === 2}
                onClick={() => handleSave(primaryFields, 2)}
              />
            </div>
          </div>

          <div className="primary-contact">
            <h5>After hours contact</h5>

            <Row>
              <Col md={6}>
                <FormInput
                  name={'afterHoursName'}
                  id={'afterHoursName'}
                  type={'text'}
                  label={'Name:'}
                  register={register}
                  rules={NameRules}
                  errors={errors}
                  divClassName="form-group pr-12"
                />
              </Col>
              <Col md={6}>
                <FormCleave
                  trigger={trigger}
                  name={'afterHoursPhone'}
                  id={'afterHoursPhone'}
                  type={'number'}
                  label={'Phone number:'}
                  control={control}
                  rules={phoneRules}
                  errors={errors}
                  divClassName="form-group pl-12"
                />
              </Col>
              <span className="sub-text-setting">
                Someone who can respond during non-business hours about
                emergencies or shift issues
              </span>
            </Row>
            <div className="text-center mt-4">
              <FormButton
                className="pt-btn btn-primary pt-btn-small"
                type={'button'}
                label={'Save'}
                loader={loader === 3}
                onClick={() =>
                  handleSave(['afterHoursName', 'afterHoursPhone'], 3)
                }
              />
            </div>
          </div>
          <div className="primary-contact">
            <h5>Office Admin or Receptionist</h5>

            <Row>
              <Col md={6}>
                <FormInput
                  name={'frontOfficePersonName'}
                  id={'frontOfficePersonName'}
                  type={'text'}
                  label={'Name:'}
                  register={register}
                  rules={{}}
                  errors={errors}
                  divClassName="form-group pr-12"
                />
              </Col>
              <Col md={6}>
                <FormCleave
                  trigger={trigger}
                  name={'frontOfficePersonPhone'}
                  id={'frontOfficePersonPhone'}
                  type={'number'}
                  label={'Phone number:'}
                  control={control}
                  rules={phoneRulesOptional}
                  errors={errors}
                  divClassName="form-group pl-12"
                />
              </Col>
            </Row>
            <div className="text-center mt-4">
              <FormButton
                className="pt-btn btn-primary pt-btn-small"
                type={'button'}
                label={'Save'}
                loader={loader === 7}
                onClick={() =>
                  handleSave(
                    ['frontOfficePersonName', 'frontOfficePersonPhone'],
                    7,
                  )
                }
              />
            </div>
          </div>
          <div className="primary-contact">
            <h5>Facility Address</h5>

            <Row>
              <Col md={12}>
                <FormInput
                  name={'officeAddressNickname'}
                  id={'officeAddressNickname'}
                  label={'Address:'}
                  placeholder="Address Nickname"
                  type={'text'}
                  register={register}
                  rules={OfficeNickNameRules}
                  errors={errors}
                  divClassName="form-group nick-name"
                />
              </Col>
              <Col md={12}>
                <PlacesInput
                  setValue={setValue}
                  register={register}
                  trigger={trigger}
                  value={getValues('officeAddress1')}
                  addressLine="officeAddress1"
                  addressState="officeAddressState"
                  addressZipcode="officeAddressZipcode"
                  error={errors['officeAddress1']}
                  placeholder="Address Line 1"
                />
                <FormInput
                  name={'officeAddress2'}
                  id={'officeAddress2'}
                  type={'text'}
                  register={register}
                  rules={{}}
                  errors={errors}
                  placeholder="Address line 2"
                  divClassName={'office-address form-group'}
                />
              </Col>
              <Col md={6}>
                <FormSelect
                  options={state}
                  placeholder="Select"
                  control={control}
                  name={'officeAddressState'}
                  errors={errors}
                  trigger={trigger}
                  optionValue="value"
                  optionLabel="label"
                  rules={StateRules}
                  divClassName="form-group pr-12"
                  label="State:"
                />
              </Col>
              <Col md={6}>
                <FormInput
                  divClassName="form-group pl-12"
                  name={'officeAddressZipCode'}
                  id={'officeAddressZipCode'}
                  type={'number'}
                  label={'Zip code:'}
                  register={register}
                  rules={ZipCodeRules}
                  errors={errors}
                />
              </Col>
              <Col md={12}>
                <div className="clinic-question-ans">
                  <label>
                    Share more about your facility and your expectations
                    (Highlight for clinicians why they would want to pick up a
                    shift at your clinic)
                  </label>

                  <FormInput
                    id="officeAddressDescription"
                    name="officeAddressDescription"
                    divClassName={'form-group office-address'}
                    register={register}
                    errors={errors}
                    type={'textarea'}
                    maxLength={1500}
                    rules={AboutFacilityRules}
                    placeholder={
                      'Example:  We are a small clinic. We see predominately musculoskeletal conditions in middle age adults with a sprinkle of total joints or neuro. Patients are scheduled every 45 minutes and get 1:1 care. We have techs to assist in setting up and cleaning.'
                    }
                  />
                </div>
              </Col>
            </Row>

            {locaFields && locaFields?.length > 0 && (
              <div className="divider-form">
                {locaFields &&
                  locaFields?.length > 0 &&
                  locaFields.map((loca, index) => {
                    return (
                      <Row key={loca.id}>
                        <h5 style={{ marginTop: '10px' }}>Facility Location {index + 2}</h5>
                        <Col md={6}>
                        <div className="picture-block">
                          <p className="mb-0">Profile Picture (optional)</p>
                          <div className="uploaded-pic">
                            <UploadFile
                              multiple={false}
                              serverFiles={
                                getValues(
                                  `secondaryAddresses[${index}].officeAddressPic`,
                                )
                                  ? [
                                      getValues(
                                        `secondaryAddresses[${index}].officeAddressPic`,
                                      ),
                                    ]
                                  : []
                              }
                              max={1}
                              id={`secondaryAddresses[${index}].officeAddressPic`}
                              accept={ACCEPT_IMAGE}
                              folder="images"
                              callbackFn={(res) => {
                                setValue(
                                  `secondaryAddresses[${index}].officeAddressPic`,
                                  res[0] || null,
                                );
                              }}
                              deleteCallbackFn={() =>
                                setValue(
                                  `secondaryAddresses[${index}].officeAddressPic`,
                                  null,
                                )
                              }
                            />
                          </div>
                        </div>
                      </Col>

                        <Col md={12}>
                          <FormInput
                            name={`secondaryAddresses[${index}].officeAddressNickname`}
                            id={`secondaryAddresses[${index}].officeAddressNickname`}
                            label={'Nickname: '}
                            type={'text'}
                            register={register}
                            rules={OfficeNickNameRules}
                            errors={errors}
                            divClassName="form-group nick-name"
                            errorMsg={
                              errors['secondaryAddresses']?.[index]?.[
                                'officeAddressNickname'
                              ]?.message
                            }
                          />
                        </Col>
                        <Col md={12}>
                        <div className="form-group">
                          <PlacesInput
                            setValue={setValue}
                            register={register}
                            trigger={trigger}
                            value={getValues(
                              `secondaryAddresses[${index}].officeAddress1`,
                            )}
                            addressLine={`secondaryAddresses[${index}].officeAddress1`}
                            addressState={`secondaryAddresses[${index}].officeAddressState`}
                            addressZipcode={`secondaryAddresses[${index}].officeAddressZipCode`}
                            error={
                              errors['secondaryAddresses']?.[index]?.[
                                'officeAddress1'
                              ]
                            }
                            placeholder="Address Line 1"
                          />
                        </div>
                          <FormInput
                            name={`secondaryAddresses[${index}].officeAddress2`}
                            id={`secondaryAddresses[${index}].officeAddress2`}
                            type={'text'}
                            register={register}
                            rules={{}}
                            errors={errors}
                            placeholder="Address line 2"
                            divClassName={'office-address form-group'}
                          />
                        </Col>
                        <Col md={6}>
                          <FormSelect
                            options={state}
                            placeholder="Select"
                            control={control}
                            trigger={trigger}
                            name={`secondaryAddresses[${index}].officeAddressState`}
                            errors={errors}
                            optionValue="value"
                            optionLabel="label"
                            rules={StateRules}
                            divClassName="form-group pr-12"
                            errorMsg={
                              errors['secondaryAddresses']?.[index]?.[
                                'officeAddressState'
                              ]?.message
                            }
                            label="State:"
                          />
                        </Col>
                        <Col md={6}>
                          <FormInput
                            divClassName="form-group pl-12"
                            name={`secondaryAddresses[${index}].officeAddressZipCode`}
                            id={`secondaryAddresses[${index}].officeAddressZipCode`}
                            type={'number'}
                            label={'Zip code:'}
                            register={register}
                            rules={ZipCodeRules}
                            errors={errors}
                            errorMsg={
                              errors['secondaryAddresses']?.[index]?.[
                                'officeAddressZipCode'
                              ]?.message
                            }
                          />
                        </Col>
                        <Col md={12}>
                          <div className="clinic-question-ans">
                            <label>
                              Share more about your facility and your
                              expectations (Highlight for clinicians why they
                              would want to pick up a shift at your clinic)
                            </label>

                            <FormInput
                              id={`secondaryAddresses[${index}].officeAddressDescription`}
                              name={`secondaryAddresses[${index}].officeAddressDescription`}
                              divClassName={'form-group office-address'}
                              register={register}
                              errors={errors}
                              type={'textarea'}
                              maxLength={1500}
                              rules={AboutFacilityRules}
                              placeholder={
                                'Example:  We are a small clinic. We see predominately musculoskeletal conditions in middle age adults with a sprinkle of total joints or neuro. Patients are scheduled every 45 minutes and get 1:1 care. We have techs to assist in setting up and cleaning.'
                              }
                              errorMsg={
                                errors['secondaryAddresses']?.[index]?.[
                                  'officeAddressDescription'
                                ]?.message
                              }
                            />
                          </div>
                        </Col>

                        <div className="d-grid add-remove-btn">
                          {index === locaFields.length - 1 && (
                            <button
                              type="button"
                              className="btn-link add-btn"
                              onClick={() => locaAppend({})}>
                              {' '}
                              + Add Location{' '}
                            </button>
                          )}
                          <button
                            type="button"
                            className="btn-link-secondary remove-btn"
                            onClick={() => {
                              locaRemove({ index });
                            }}>
                            <Close />
                            Remove
                          </button>
                        </div>
                      </Row>
                    );
                  })}
              </div>
            )}
            {locaFields?.length <= 0 && (
              <button
                type="button"
                className="btn-link add-btn"
                onClick={() => locaAppend({})}>
                {' '}
                + Add Location{' '}
              </button>
            )}
            <div className="text-center mt-4">
              <FormButton
                className="pt-btn btn-primary pt-btn-small"
                type={'button'}
                label={'Save'}
                loader={loader === 4}
                onClick={() =>
                  handleSave(
                    [
                      'secondaryAddresses',
                      'officeAddress1',
                      'officeAddressNickname',
                      'officeAddress2',
                      'officeAddressState',
                      'officeAddressZipCode',
                      'officeAddressDescription',
                      'lat',
                      'long',
                    ],
                    4,
                  )
                }
              />
            </div>
          </div>

          <div className="primary-contact">
            <h5>Clinic details</h5>

            <Row>
              <Col md={6}>
                <FormInput
                  divClassName="form-group pr-12 pl-input"
                  type="text"
                  id="numberOfClinicians"
                  name="numberOfClinicians"
                  register={register}
                  rules={NumberOfCliniciansRules}
                  errors={errors}
                  label="Number of clinicians currently at this facility:"
                  placeholder={'Ex: 5 PTs, 3 PTAs, 1 OT and 2 techs'}
                />
              </Col>
            </Row>
            <div className="clinic-detail-block">
              <div className="clinic-detail-box border-bottom">
                <label>Type of facility:</label>
                <div className="clinic-checkbox">
                  <FormCheckboxOther
                    control={control}
                    options={facilityTypeOptions}
                    register={register}
                    errors={errors}
                    name="facilityType"
                    rules={TypeOfFacilityRules}
                    divClassName="checkbox-box"
                  />
                </div>
                <FormInput
                  disabled={!facilityType.includes('Other')}
                  divClassName="textarea"
                  name={'facilityTypeOther'}
                  id={'facilityTypeOther'}
                  type={'textarea'}
                  label={''}
                  register={register}
                  rules={facilityType.includes('Other') ? CommentRules : {}}
                  errors={errors}
                  maxLength={MAX_LENGTH}
                />
              </div>
              <div className="clinic-detail-box border-bottom">
                <label>Type(s) of patients:</label>
                <div className="clinic-checkbox">
                  <FormCheckboxOther
                    control={control}
                    options={patientTypeOptions}
                    register={register}
                    errors={errors}
                    rules={TypeOfPatientsRules}
                    name="patientsType"
                    divClassName="checkbox-box"
                  />
                </div>
                <FormInput
                  disabled={!patientsType.includes('Other')}
                  divClassName="textarea"
                  name={'patientsTypeOther'}
                  id={'patientsTypeOther'}
                  type={'textarea'}
                  label={''}
                  register={register}
                  rules={patientsType.includes('Other') ? CommentRules : {}}
                  errors={errors}
                  maxLength={MAX_LENGTH}
                />
              </div>
              <div className="clinic-detail-box border-bottom">
                <label>Dress code:</label>
                <div className="clinic-checkbox">
                  <FormCheckboxOther
                    control={control}
                    options={dressCodeOptions}
                    register={register}
                    rules={DressCodeRules}
                    errors={errors}
                    name="dressCode"
                    divClassName="checkbox-box dress"
                    onChangeFor={(label) => {
                      let text = dressCodeOther;
                      unregister('dressCodeOther');
                      register('dressCodeOther');
                      setValue('dressCodeOther', text);
                    }}
                  />
                </div>
                <FormInput
                  divClassName="textarea pl-input"
                  name={'dressCodeOther'}
                  id={'dressCodeOther'}
                  type={'textarea'}
                  register={register}
                  rules={dressCode.includes('Other') ? CommentRules : {}}
                  errors={errors}
                  maxLength={MAX_LENGTH}
                  placeholder={'Anything specific about the dress code?'}
                />
                <hr style={{ borderTop: '1px dashed #80d3cb', opacity: 1 }} />
                <FormInput
                  divClassName="pl-input"
                  name={'patientsSeenPerHour'}
                  id={'patientsSeenPerHour'}
                  type={'text'}
                  className="w-100 mt-2"
                  label={'Number of patients seen per hour:'}
                  register={register}
                  rules={PatientSeenPerHourRules}
                  errors={errors}
                  placeholder={
                    'Example: 1 per hour. 1-2 per hour. 2 per hour with tech assistance. 45 minute treatment sessions.'
                  }
                />
              </div>
              <div className="clinic-detail-box border-bottom">
                <label>What EMR do you use?</label>
                <FormInput
                  divClassName="textarea"
                  name={'emrList'}
                  id={'emrList'}
                  type={'textarea'}
                  label={''}
                  register={register}
                  rules={EmrRules}
                  errors={errors}
                  maxLength={MAX_LENGTH}
                />
                <hr style={{ borderTop: '1px dashed #80d3cb', opacity: 1 }} />
                <p className="mt-2">Is CPR required?</p>
                <div className="cpr-radio">
                  <FormRadio
                    name={'isCPRRequired'}
                    options={boolList}
                    register={register}
                    rules={isCPRRequiredRules}
                    errors={errors}
                    divClassName="pt-radio"
                  />
                </div>
                <FormInput
                  divClassName="signup-input-psph mt-2"
                  name={'CPRRequirement'}
                  id={'CPRRequirement'}
                  type={'text'}
                  register={register}
                  rules={{}}
                  errors={errors}
                  label={
                    'If yes, list any specific requirements (BLS, AHA-certified, etc)'
                  }
                />
              </div>

              {/* <div className="clinic-detail-box">
                <label>Are there training videos for clinician to watch?</label>
                <div className="radio-main">
                  <FormRadio
                    name={"isTrainingVideosForClinician"}
                    options={boolList}
                    register={register}
                    rules={IsTrainingVideosForClinicianRules}
                    errors={errors}
                    divClassName="pt-radio"
                  />
                </div>
              </div> */}
            </div>
            <div className="clinic-detail-box border-bottom-dash mb-12">
              <label>Where do clinicians check in on their first day:</label>
              <FormInput
                name="checkInPlaceForFirstDay"
                id="checkInPlaceForFirstDay"
                divClassName={'signup-input situation pl-input'}
                register={register}
                errors={errors}
                rules={checkInPlaceForFirstDayRules}
                maxLength={MAX_LENGTH}
                placeholder={
                  'Example: 2nd Floor receptionist, first floor front office manager, etc'
                }
              />
            </div>
            <div className="clinic-question-ans mb-12">
              <label>
                What is the parking situation? (Please be specific. Include
                location of specific lot, surface vs garage)
              </label>
              <FormInput
                name="parkingSituation"
                id="parkingSituation"
                register={register}
                errors={errors}
                divClassName={'signup-input share'}
                maxLength={MAX_LENGTH}
              />
            </div>
            <div className="text-center mt-4">
              <FormButton
                className="pt-btn btn-primary pt-btn-small"
                type={'button'}
                label={'Save'}
                loader={loader === 5}
                onClick={() => handleSave(clientDetails, 5)}
              />
            </div>
          </div>
          <div className="primary-contact border-0 pb-0 mb-0">
            <h5>Invoice section</h5>
            <div className="w-100">
              <h6 className="mt-2">How would you like to pay invoices:</h6>
              <div className="">
                <FormRadio
                  name={'invoiceMethod'}
                  options={[
                    { label: 'Paper check', value: 'Paper check' },
                    {
                      label: 'ACH/Online bank transfer',
                      value: 'Online bank transfer',
                    },
                    { label: 'Credit card*', value: 'Credit card' },
                  ]}
                  register={register}
                  rules={invoiceMethodRules}
                  errors={errors}
                  divClassName="pt-radio im-radio mb-2"
                />
              </div>
              <p
                className="sub-text-setting"
                style={{ marginBottom: 0 }}>
                We invoice weekly unless otherwise agreed
              </p>
              <p className="sub-text-settings">
                *May incur a small processing fee, please see facility agreement
              </p>
            </div>

            <div className="signup-third-btm-textarea">
              <h6>Additional emails to copy on the invoices (optional):</h6>

              <FormInput
                name="emailsForInvoice[0]"
                id="emailsForInvoice0"
                divClassName={'signup-input situation'}
                register={register}
                errors={errors}
                rules={{}}
                maxLength={MAX_LENGTH}
                errorMsg={errors?.emailsForInvoice?.[0]?.message || ''}
              />
              <FormInput
                name="emailsForInvoice[1]"
                id="emailsForInvoice1"
                divClassName={'signup-input situation'}
                register={register}
                errors={errors}
                rules={{}}
                maxLength={MAX_LENGTH}
                errorMsg={errors?.emailsForInvoice?.[1]?.message || ''}
              />
              <FormInput
                name="emailsForInvoice[2]"
                id="emailsForInvoice2"
                divClassName={'signup-input situation'}
                register={register}
                errors={errors}
                rules={{}}
                maxLength={MAX_LENGTH}
                errorMsg={errors?.emailsForInvoice?.[2]?.message || ''}
              />
            </div>
            <div className="text-center mt-4">
              <FormButton
                className="pt-btn btn-primary pt-btn-small"
                type={'button'}
                label={'Save'}
                loader={loader === 6}
                onClick={() => handleSave(invoiceFields, 6)}
              />
            </div>
          </div>
        </fieldset>
      </Form>
      <EditEmailModal
        modal={editEmailModal}
        toggle={() => setEditEmailModal(!editEmailModal)}
        data={initData}
        callbackFn={() => {
          setEditEmailModal(!editEmailModal);
          getFacilityById();
        }}
      />
      <ResetFacilityPasswordModal
        modal={resetPasswordModal}
        toggle={() => setResetPasswordModal(!resetPasswordModal)}
        data={initData}
        callbackFn={() => {
          setResetPasswordModal(!resetPasswordModal);
          handleReset();
        }}
      />
    </div>
  );
}
